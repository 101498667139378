import Layout from "../../components/reusable/Layout";
import classes from "./SupplementsSmartFit.module.css";
import smartFitLogo from "../../images/smartFit/smartFitLogo.png";
import humanPro from "../../images/smartFit/humanPro.png";
import creatine from "../../images/smartFit/creatine.png";
import electrolytes_Ultima from "../../images/smartFit/electrolytes_Ultima.png";
import electrolytes_LMNT from "../../images/smartFit/electrolytes_LMNT.png";

const KeyDetailsTableData = [
  {
    ROW: 1,
    VALUES: [
      "Manufacturer",
      "Ingredient",
      "Amino Acid",
      "Form",
      "Serving Size",
      "Serving Size",
    ],
  },
  {
    ROW: 2,
    VALUES: [
      "ALR Industries",
      "Essential Amino Acids (EAA)",
      "Contains all 8 essential amino acids, necessary for muscle recovery and growth.",
      "Available in tablet and powder form.",
      "5 tablets or 1 scoop (powder) = ~25g of whole food protein.",
      "Extremely low in calories (~0.02 per serving for powder).",
    ],
  },
];

const ultimaReplenisherTableData = [
  {
    ROW: 1,
    VALUES: [
      "Type",
      "Ingredients",
      "Dietary Info",
      "Extra Nutrients",
      "Sugar",
      "Calories",
      "Flavors",
    ],
  },
  {
    ROW: 2,
    VALUES: [
      "Electrolyte hydration powder",
      "Magnesium, potassium, sodium, calcium, phosphorus, chloride",
      "Vegan, gluten-free, keto-friendly, non-GMO",
      "Vitamin C, zinc, antioxidants",
      "0g, sweetened with stevia",
      "0–15 calories per serving (varies by flavor)",
      "Raspberry, Lemonade, Grape, Cherry Pomegranate, Watermelon",
    ],
  },
];

const electrolyteLMNTTableData = [
  {
    ROW: 1,
    VALUES: [
      "Type",
      "Ingredients",
      "Dietary Info",
      "Sugar",
      "Calories",
      "Flavors",
    ],
  },
  {
    ROW: 2,
    VALUES: [
      "Electrolyte drink mix",
      "High sodium (1000mg), potassium (200mg), magnesium (60mg)",
      "Vegan, gluten-free, paleo- and keto-friendly",
      "0g, sweetened with stevia",
      "10 calories per serving",
      "Citrus Salt, Raspberry Salt, Orange Salt, Raw Unflavored",
    ],
  },
];

const whichOneToChooseTableData = [
  {
    ROW: 1,
    VALUES: ["Product", "Best For", "Key Features"],
  },
  {
    ROW: 2,
    VALUES: [
      "Ultima",
      "General hydration, light workouts, or individuals needing gentle electrolyte support.",
      "Low sodium, balanced hydration, 0 sugar, vegan, daily electrolyte replenishment.",
    ],
  },
  {
    ROW: 3,
    VALUES: [
      "LMNT",
      "Intense workouts, high sodium needs, or athletes losing significant electrolytes.",
      "High sodium (1000mg), 0 sugar, vegan, designed for heavy sweating, keto/low-carb diets.",
    ],
  },
];

const promixCreatineTableData = [
  {
    ROW: 1,
    VALUES: [
      "Type",
      "Ingredients",
      "Dietary Info",
      "Serving",
      "Sugar",
      "Free From",
      "From",
    ],
  },
  {
    ROW: 2,
    VALUES: [
      "Creatine Monohydrate Supplement",
      "100% pure Creatine Monohydrate (5g per serving)",
      "Vegan-friendly",
      "1 scoop (5g) daily, 0 calories",
      "0g, sweetened with stevia",
      "Non-GMO, gluten-free, soy-free, no additives, fillers, or artificial ingredients",
      "Powder (unflavored, dissolves easily in water or other beverages)",
    ],
  },
];

type AvatarData = {
  type: number;
  color: string;
  programme: [
    {
      title: string;
      subtitle: string;
      eccentricDuration: string;
      isometricDuration: string;
      concentricDuration: string;
      workoutFocusList: string[];
      descriptionList: string[];
      smartStrengthTableData: { ROW: number; VALUES: string[] }[];
    },
    {
      title: string;
      subtitle: string;
      orientationTableData: { ROW: number; VALUES: string[] }[];
      regularScheduleTableData: { ROW: number; VALUES: string[] }[];
    },
    {
      title: string;
      subtitle: string;
      coldHIITTableData: { ROW: number; VALUES: string[] }[];
      protocolDescriptionList: { title: string; description: string[] }[];
    }
  ];
};

const avatarData: AvatarData[] = [
  {
    type: 1,
    color: "#FFF0F1",
    programme: [
      {
        title: "Smart strength",
        subtitle: "Optimize hormones & build strength - 1 Training day/week",
        eccentricDuration: "10s",
        isometricDuration: "10s",
        concentricDuration: "5s",
        smartStrengthTableData: [
          {
            ROW: 1,
            VALUES: [
              "Quarter",
              "Exercise 1",
              "Exercise 2",
              "Exercise 3",
              "Exercise 4",
              "Exercise 5",
              "Exercise 6",
            ],
          },
          {
            ROW: 2,
            VALUES: [
              "Day 1",
              "Glute Machine or Torso Extension",
              "Leg Press",
              "Row",
              "Horizontal Press",
              "Lat Pulldown",
              "Overhead Press",
            ],
          },
        ],
        workoutFocusList: [
          "Optimizing hormonal balance.",
          "Developing strength with low-frequency training.",
          "Targeting the entire body with slow, controlled movements.",
        ],
        descriptionList: [
          "Slow, controlled movements to maximize results, improve strength, and ensure safety.",
          "Increased time under tension (TUT) for greater muscle activation and enhanced strength development.",
          "A balanced approach targeting major muscle groups through pressing, pulling, and leg exercises.",
        ],
      },
      {
        title: "Smart ride (time dependent)",
        subtitle: "Cycling Training Program",
        orientationTableData: [
          {
            ROW: 1,
            VALUES: ["Session", "Avatar 1"],
          },
          {
            ROW: 2,
            VALUES: ["Day 1", "Calibration Ride 1 (25min)"],
          },
          {
            ROW: 3,
            VALUES: ["Day 2", "Calibration Ride 2 (25min)"],
          },
          {
            ROW: 4,
            VALUES: ["Day 3", "Calibration Ride 3 (25min)"],
          },
        ],
        regularScheduleTableData: [
          {
            ROW: 1,
            VALUES: ["Session", "Avatar 1"],
          },
          {
            ROW: 2,
            VALUES: ["Day 1", "Zone Ride (25min each)"],
          },
          {
            ROW: 3,
            VALUES: ["Day 2", "Zone Ride (25min each)"],
          },
          {
            ROW: 4,
            VALUES: ["Day 3", "Zone Ride (25min each)"],
          },
        ],
      },
      {
        title: "Cold HIIT",
        subtitle: "Introductory Level",
        coldHIITTableData: [
          {
            ROW: 1,
            VALUES: ["Session", "Avatar 1"],
          },
          {
            ROW: 2,
            VALUES: ["Day 1", "Humming Bird"],
          },
          {
            ROW: 3,
            VALUES: ["Day 2", "Smooth Sailing"],
          },
          {
            ROW: 4,
            VALUES: ["Day 3", "Cheetah"],
          },
          {
            ROW: 5,
            VALUES: ["Day 4", "Super 6"],
          },
        ],
        protocolDescriptionList: [
          {
            title: "Humming Bird",
            description: [
              "Light protocol with cold exposure and simple moves.",
            ],
          },
          {
            title: "Smooth Sailing",
            description: ["Moderate cold workout at a steady pace."],
          },
          {
            title: "Cheetah",
            description: ["High-speed intervals for agility and stamina."],
          },
          {
            title: "Super 6",
            description: ["Full-body cold HIIT for strength and endurance."],
          },
        ],
      },
    ],
  },
  {
    type: 2,
    color: "#FFF9E6",
    programme: [
      {
        title: "Smart strength",
        subtitle: "Dynamic Protocol (Every 4 Days)",
        eccentricDuration: "10s",
        isometricDuration: "10s",
        concentricDuration: "2.5s",
        smartStrengthTableData: [
          {
            ROW: 1,
            VALUES: [
              "Quarter",
              "Exercise 1",
              "Exercise 2",
              "Exercise 3",
              "Exercise 4",
              "Exercise 5",
              "Exercise 6",
            ],
          },
          {
            ROW: 2,
            VALUES: [
              "Day 1",
              "Glute Machine or Torso Extension",
              "Leg Press",
              "Row",
              "Horizontal Press",
              "Lat Pulldown",
              "Overhead Press",
            ],
          },
        ],
        workoutFocusList: [
          "Avatar 2 uses a dynamic approach that shifts focus every four days, ensuring balanced muscle engagement.",
          "The varied grips and exercise variations promote recovery and muscular development.",
        ],
        descriptionList: [
          "Avatar 2 focuses on a dynamic blend of compound exercises and grip variations to improve muscle engagement and functionality.",
          "This variation every four days encourages recovery and targeted strength improvements while preventing overuse injuries.",
        ],
      },
      {
        title: "Smart ride",
        subtitle: "Cycling Training Program",
        orientationTableData: [
          {
            ROW: 1,
            VALUES: ["Session", "Avatar 2"],
          },
          {
            ROW: 2,
            VALUES: ["Day 1", "Calibration Ride 1 (25min)"],
          },
          {
            ROW: 3,
            VALUES: ["Day 2", "Calibration Ride 2 (25min)"],
          },
          {
            ROW: 4,
            VALUES: ["Day 3", "Calibration Ride 3 (25min)"],
          },
        ],
        regularScheduleTableData: [
          {
            ROW: 1,
            VALUES: ["Session", "Avatar 2"],
          },
          {
            ROW: 2,
            VALUES: ["Day 1", "Zone Ride (25min each)"],
          },
          {
            ROW: 3,
            VALUES: ["Day 2", "REHIIT/Fatburn (30min)"],
          },
          {
            ROW: 4,
            VALUES: ["Day 3", "Zone Ride (25min each)"],
          },
        ],
      },
      {
        title: "Cold HIIT",
        subtitle: "Introductory Level",
        coldHIITTableData: [
          {
            ROW: 1,
            VALUES: ["Session", "Avatar 2"],
          },
          {
            ROW: 2,
            VALUES: ["Day 1", "Humming Bird"],
          },
          {
            ROW: 3,
            VALUES: ["Day 2", "Burn Baby Burn"],
          },
          {
            ROW: 4,
            VALUES: ["Day 3", "Cheetah"],
          },
          {
            ROW: 5,
            VALUES: ["Day 4", "Super 6"],
          },
        ],
        protocolDescriptionList: [
          {
            title: "Humming Bird",
            description: [
              "Light protocol with cold exposure and simple moves.",
            ],
          },
          {
            title: "Burn Baby Burn",
            description: [
              "A fat-burning cold HIIT with increased intensity intervals.",
            ],
          },
          {
            title: "Cheetah",
            description: ["High-speed intervals for agility and stamina."],
          },
          {
            title: "Super 6",
            description: ["Full-body cold HIIT for strength and endurance."],
          },
        ],
      },
    ],
  },
  {
    type: 3,
    color: "#E6F0FF",
    programme: [
      {
        title: "Smart strength",
        subtitle: "Dynamic Protocol (Every 4 Days)",
        eccentricDuration: "10s",
        isometricDuration: "10s",
        concentricDuration: "2.5s",
        smartStrengthTableData: [
          {
            ROW: 1,
            VALUES: [
              "Quarter",
              "Exercise 1",
              "Exercise 2",
              "Exercise 3",
              "Exercise 4",
              "Exercise 5",
              "Exercise 6",
            ],
          },
          {
            ROW: 2,
            VALUES: [
              "Day 1",
              "Glute Machine or Torso Extension",
              "Leg Press",
              "Row",
              "Horizontal Press",
              "Lat Pulldown",
              "Overhead Press",
            ],
          },
        ],
        workoutFocusList: [
          "This protocol emphasizes rapid muscle engagement for those seeking efficiency in their workouts with zero pauses between exercises, keeping intensity high.",
        ],
        descriptionList: [
          "Avatar 3 is built for personal fitness enthusiasts aiming for time-efficient, high-impact workouts. ",
          "It emphasizes strength, endurance, and functional fitness by rotating between pulling, pressing, and isolation movements, with no breaks for intensity maximization.",
        ],
      },
      {
        title: "Smart ride",
        subtitle: "Cycling Training Program",
        orientationTableData: [
          {
            ROW: 1,
            VALUES: ["Session", "Avatar 3"],
          },
          {
            ROW: 2,
            VALUES: ["Day 1", "Calibration Ride 1 (25min)"],
          },
          {
            ROW: 3,
            VALUES: ["Day 2", "Calibration Ride 2 (25min)"],
          },
          {
            ROW: 4,
            VALUES: ["Day 3", "Calibration Ride 3 (25min)"],
          },
        ],
        regularScheduleTableData: [
          {
            ROW: 1,
            VALUES: ["Session", "Avatar 3"],
          },
          {
            ROW: 2,
            VALUES: ["Day 1", "REHIIT/Fatburn 45 (25min)"],
          },
          {
            ROW: 3,
            VALUES: ["Day 2", "Zone Ride (25min each)"],
          },
          {
            ROW: 4,
            VALUES: ["Day 3", "Norwegian Protocol (30min)"],
          },
        ],
      },
      {
        title: "Cold HIIT",
        subtitle: "Introductory Level",
        coldHIITTableData: [
          {
            ROW: 1,
            VALUES: ["Session", "Avatar 3"],
          },
          {
            ROW: 2,
            VALUES: ["Day 1", "Super 7 Fusion"],
          },
          {
            ROW: 3,
            VALUES: ["Day 2", "Burn Baby Burn"],
          },
          {
            ROW: 4,
            VALUES: ["Day 3", "Cheetah"],
          },
          {
            ROW: 5,
            VALUES: ["Day 4", "Space Mountain"],
          },
        ],
        protocolDescriptionList: [
          {
            title: "Super 7 Fusion",
            description: [
              "A well-rounded protocol combining intensity variation to boost cardiovascular fitness.",
            ],
          },
          {
            title: "Burn Baby Burn",
            description: [
              "A fat-burning cold HIIT with increased intensity intervals.",
            ],
          },
          {
            title: "Cheetah",
            description: ["High-speed intervals for agility and stamina."],
          },
          {
            title: "Space Mountain",
            description: [
              "Alternating intensity intervals targeting endurance and recovery.",
            ],
          },
        ],
      },
    ],
  },
  {
    type: 4,
    color: "#DCF9ED",
    programme: [
      {
        title: "Smart strength",
        subtitle: "Super Strength Protocol (2x a Week)",
        eccentricDuration: "10s",
        isometricDuration: "10s",
        concentricDuration: "0s",
        smartStrengthTableData: [
          {
            ROW: 1,
            VALUES: [
              "Quarter",
              "Exercise 1",
              "Exercise 2",
              "Exercise 3",
              "Exercise 4",
              "Exercise 5",
              "Exercise 6",
            ],
          },
          {
            ROW: 2,
            VALUES: [
              "Day 1",
              "Glute Machine or Torso Extension",
              "Leg Press",
              "Row",
              "Horizontal Press",
              "Lat Pulldown",
              "Overhead Press",
            ],
          },
        ],
        workoutFocusList: [
          "The Super Strength protocol is tailored for high-performance individuals or athletes seeking to maximize strength gains, with an emphasis on heavy compound lifts and functional movements.",
        ],
        descriptionList: [
          "Avatar 4 is designed for athletes focused on enhancing overall power, strength, and muscle endurance. ",
          "By combining big lifts with functional movements, the protocol enhances both upper and lower body strength with an added focus on joint stability through isolation exercises.",
        ],
      },
      {
        title: "Smart ride",
        subtitle: "Cycling Training Program",
        orientationTableData: [
          {
            ROW: 1,
            VALUES: ["Session", "Avatar 4"],
          },
          {
            ROW: 2,
            VALUES: ["Day 1", "Calibration Ride 1 (25min)"],
          },
          {
            ROW: 3,
            VALUES: ["Day 2", "Calibration Ride 2 (25min)"],
          },
          {
            ROW: 4,
            VALUES: ["Day 3", "Calibration Ride 3 (25min)"],
          },
        ],
        regularScheduleTableData: [
          {
            ROW: 1,
            VALUES: ["Session", "Avatar 4"],
          },
          {
            ROW: 2,
            VALUES: ["Day 1", "Norwegian Protocol (30min)"],
          },
          {
            ROW: 3,
            VALUES: ["Day 2", "REHIIT/Fatburn 45 (25min)"],
          },
          {
            ROW: 4,
            VALUES: ["Day 3", "Norwegian Protocol (30min)"],
          },
        ],
      },
      {
        title: "Cold HIIT",
        subtitle: "Introductory Level",
        coldHIITTableData: [
          {
            ROW: 1,
            VALUES: ["Session", "Avatar 4"],
          },
          {
            ROW: 2,
            VALUES: ["Day 1", "Monster"],
          },
          {
            ROW: 3,
            VALUES: ["Day 2", "Super 8"],
          },
          {
            ROW: 4,
            VALUES: ["Day 3", "Cheetah"],
          },
          {
            ROW: 5,
            VALUES: ["Day 4", "Tabata"],
          },
        ],
        protocolDescriptionList: [
          {
            title: "Monster",
            description: [
              "High-level performance protocol pushing limits of strength and cold endurance.",
            ],
          },
          {
            title: "Super 8",
            description: [
              "A challenging cold HIIT with extended intervals for building elite endurance.",
            ],
          },
          {
            title: "Cheetah",
            description: ["High-speed intervals for agility and stamina."],
          },
          {
            title: "Tabata",
            description: [
              "Rapid, high-intensity intervals designed for athletic peak performance.",
            ],
          },
        ],
      },
    ],
  },
];

const SmartFitHeader = () => {
  return (
    <div className={classes.header}>
      <div>
        <div className={classes.headerTitle}>The Smart Supplement Pack</div>
        <div className={classes.headerSubTitle}>
          Optimized Performance, Enhanced Recovery
        </div>
      </div>
      <img src={smartFitLogo} alt="smart-fit-logo" />
    </div>
  );
};

const List = ({
  title,
  items,
  customGap,
}: {
  title: string;
  items: string[];
  customGap?: string;
}) => {
  return (
    <div className={classes.listContainer}>
      <div className={classes.listTitle}>{title}</div>
      <ul className={classes.listInner} style={{ gap: customGap ?? "" }}>
        {items.map((el: string, index: number) => (
          <li key={index} className={classes.listItem}>
            {el}
          </li>
        ))}
      </ul>
    </div>
  );
};

const Table = ({
  data,
  smallFirstColumn,
  customMinHeight,
  boldFirstColumn,
}: {
  data: { ROW: number; VALUES: string[] }[];
  smallFirstColumn?: boolean;
  customMinHeight?: string;
  boldFirstColumn?: boolean;
}) => {
  return (
    <div className={classes.tableWrapper}>
      {data.map((row: any, rowIndex: number) => (
        <div key={rowIndex} className={classes.rowWrapper}>
          {row.VALUES.map((column: string, index: number) => (
            <div
              key={index}
              className={classes.cell}
              style={{
                flex: smallFirstColumn && index === 0 ? 0.5 : 1,
                fontFamily:
                  rowIndex === 0 || (boldFirstColumn && index === 0)
                    ? "AktivGroteskEx_A_Bd"
                    : "",
                minHeight: rowIndex !== 0 ? customMinHeight ?? "" : "",
              }}
            >
              {column}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const SupplementsSmartFit = ({
  amrCategory,
}: {
  amrCategory: number;
}): JSX.Element => {
  function extractStringAfterPipe(input: string) {
    const parts = input.split("|");
    return parts.length > 1 ? parts[1].trim() : "";
  }

  let data;

  if (amrCategory > 95) {
    data = avatarData[3];
  } else if (amrCategory > 60) {
    data = avatarData[2];
  } else if (amrCategory > 30) {
    data = avatarData[1];
  } else {
    data = avatarData[0];
  }

  return (
    <>
      {data && (
        <Layout>
          <div className={classes.pageTitle}>Training program</div>
          <div className={classes.section}>
            <div>
              <span
                className={classes.sectionTitleV2}
              >{`Avatar ${data.type}: `}</span>
              <span
                className={classes.sectionTitleV2}
                style={{ fontFamily: "AktivGroteskEx_A_Md" }}
              >
                {data.programme[0].title}
              </span>
            </div>
            <div className={classes.sectionTitleV2}>
              {data.programme[0].subtitle}
            </div>
            <div style={{ display: "flex", gap: "16px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  flex: 1.3,
                }}
              >
                <div
                  className={classes.cardContainer}
                  style={{ flexDirection: "column", gap: "0" }}
                >
                  <div
                    className={classes.sectionTitleV2}
                    style={{ marginBottom: "8px" }}
                  >
                    Time per exercise
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      className={classes.containerPerExercise}
                      style={{ backgroundColor: data.color }}
                    >{`${data.programme[0].eccentricDuration} Concentric`}</div>
                    <div
                      className={classes.containerPerExercise}
                      style={{ backgroundColor: data.color }}
                    >{`${data.programme[0].isometricDuration} Eccentric`}</div>
                    <div
                      className={classes.containerPerExercise}
                      style={{ backgroundColor: data.color }}
                    >{`${data.programme[0].concentricDuration} Pause`}</div>
                  </div>
                </div>
                <div className={classes.cardContainer}>
                  <List
                    title={"Workout focus"}
                    items={data.programme[0].workoutFocusList || []}
                  />
                </div>
              </div>
              <div className={classes.cardContainer}>
                <List
                  title={"Description"}
                  items={data.programme[0].descriptionList || []}
                />
              </div>
            </div>
            <div style={{ marginTop: "16px" }}>
              <Table
                data={data.programme[0].smartStrengthTableData}
                boldFirstColumn={true}
              />
            </div>
          </div>
          <div className={classes.section} style={{ marginTop: "24px" }}>
            <div>
              <span
                className={classes.sectionTitleV2}
              >{`Avatar ${data.type}: `}</span>
              <span
                className={classes.sectionTitleV2}
                style={{ fontFamily: "AktivGroteskEx_A_Md" }}
              >
                {data.programme[1].title}
              </span>
            </div>
            <div className={classes.sectionTitleV2}>
              {data.programme[1].subtitle}
            </div>
            <div style={{ display: "flex", gap: "16px" }}>
              <div style={{ flex: 1 }}>
                <div style={{ marginBottom: "8px" }}>
                  <span className={classes.sectionTitleV2}>Phase 1: </span>
                  <span
                    className={classes.sectionTitleV2}
                    style={{ fontFamily: "AktivGroteskEx_A_Md" }}
                  >
                    Orientation (Week 1)
                  </span>
                </div>

                <Table
                  data={data.programme[1].orientationTableData || []}
                  customMinHeight={"38px"}
                  boldFirstColumn={true}
                />
              </div>

              <div style={{ flex: 1 }}>
                <div style={{ marginBottom: "8px" }}>
                  <span className={classes.sectionTitleV2}>Phase 2: </span>
                  <span
                    className={classes.sectionTitleV2}
                    style={{ fontFamily: "AktivGroteskEx_A_Md" }}
                  >
                    Regular Schedule
                  </span>
                </div>

                <Table
                  data={data.programme[1].regularScheduleTableData || []}
                  customMinHeight={"38px"}
                  boldFirstColumn={true}
                />
              </div>
            </div>
          </div>
        </Layout>
      )}
      {data && (
        <Layout>
          <div className={classes.pageTitle}>Training program</div>
          <div className={classes.section}>
            <div className={classes.sectionTitle}>Description</div>
            <div className={classes.cardContainer} style={{ gap: "24px" }}>
              <List
                title={"Zone ride"}
                items={[
                  "Low to moderate intensity ride.",
                  "Focuses on endurance and fat burning.",
                ]}
              />
              <List
                title={"REHIIT/Fatburn"}
                items={[
                  "High-intensity interval workout.",
                  "Designed to maximize fat burn efficiently.",
                ]}
              />
              <List
                title={"Norwegian Protocol"}
                items={[
                  "Alternating intense efforts and rest.",
                  "Boosts endurance and fitness.",
                ]}
              />
            </div>
          </div>
          <div className={classes.section} style={{ marginTop: "24px" }}>
            <div>
              <span
                className={classes.sectionTitleV2}
              >{`Avatar ${data.type}: `}</span>
              <span
                className={classes.sectionTitleV2}
                style={{ fontFamily: "AktivGroteskEx_A_Md" }}
              >
                {data.programme[2].title}
              </span>
            </div>
            <div className={classes.sectionTitleV2}>
              {data.programme[2].subtitle}
            </div>
            <div style={{ display: "flex", gap: "16px" }}>
              <div style={{ flex: 1 }}>
                <Table
                  data={data.programme[2].coldHIITTableData || []}
                  customMinHeight={"38px"}
                  boldFirstColumn={true}
                />
              </div>
              <div
                className={classes.cardContainer}
                style={{ flexDirection: "column", gap: "0" }}
              >
                <div className={classes.sectionTitleV2}>
                  Protocol description
                </div>
                <div style={{ display: "flex", gap: "24px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                    }}
                  >
                    {data.programme[2].protocolDescriptionList &&
                      data.programme[2].protocolDescriptionList
                        .slice(0, 2)
                        .map((el: any, index: number) => (
                          <List
                            key={index}
                            title={el.title}
                            items={el.description}
                          />
                        ))}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                    }}
                  >
                    {data.programme[2].protocolDescriptionList &&
                      data.programme[2].protocolDescriptionList
                        .slice(2, 4)
                        .map((el: any, index: number) => (
                          <List
                            key={index}
                            title={el.title}
                            items={el.description}
                          />
                        ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
      <Layout>
        <SmartFitHeader />
        <div className={classes.section}>
          <div className={classes.sectionTitle}>
            Why These 3 Supplements Work Together
          </div>
          <div style={{ display: "flex", gap: "16px" }}>
            <div className={classes.cardContainer}>
              <List
                title={"HumaPro"}
                items={[
                  "Provides essential amino acids for muscle repair, growth, and recovery.",
                  "Low in calories, supports lean muscle mass growth efficiently.",
                ]}
              />
              <img
                src={humanPro}
                alt="humanPro image"
                style={{ marginRight: "-12px" }}
              />
            </div>
            <div className={classes.cardContainer}>
              <List
                title={"Creatine (Promix)"}
                items={[
                  "Enhances strength, power, and muscle endurance.",
                  "Supports faster recovery and improved performance.",
                ]}
              />
              <img src={creatine} alt="creatine image" />
            </div>
          </div>
          <div
            className={classes.cardContainer}
            style={{ marginTop: "16px", gap: "40px" }}
          >
            <List
              title={"Electrolytes (Ultima/LMNT)"}
              items={[
                "Replenishes key electrolytes (sodium, potassium, magnesium) lost through sweat during intense exercise.",
                "Maintains hydration balance, preventing cramping and fatigue.",
              ]}
            />
            <img src={electrolytes_Ultima} alt="electrolytes_Ultima image" />
            <img src={electrolytes_LMNT} alt="electrolytes_LMNT image" />
          </div>
        </div>
        <div className={classes.section} style={{ marginTop: "24px" }}>
          <div className={classes.sectionTitle}>
            Why They Work Well Together
          </div>
          <div className={classes.cardContainer}>
            <List
              title={"Performance Support"}
              items={[
                "Creatine powers high-intensity efforts, HumaPro repairs and builds muscles, and electrolytes maintain proper hydration.",
              ]}
            />
            <List
              title={"Optimized Recovery"}
              items={[
                "Amino acids repair muscles, creatine replenishes energy, and electrolytes prevent dehydration for faster recovery.",
              ]}
            />
            <List
              title={"Synergistic Effects"}
              items={[
                "Combines muscle-building, hydration, and energy recovery for achieving maximum strength, endurance, and performance.",
              ]}
            />
          </div>
        </div>
        <div className={classes.section} style={{ marginTop: "24px" }}>
          <div className={classes.sectionTitle}>
            HumaPro - Protein Alternative Supplement
          </div>
          <div className={classes.cardContainer}>
            <List
              title={"Description"}
              items={[
                "HumaPro is a dietary supplement designed as an alternative to traditional protein sources. It supports muscle building and recovery by providing essential amino acids in a concentrated, efficient form.",
              ]}
            />
          </div>
        </div>
        <div className={classes.section} style={{ marginTop: "24px" }}>
          <div className={classes.sectionTitle}>Key details</div>
          <Table data={KeyDetailsTableData} />
        </div>
      </Layout>
      <Layout>
        <SmartFitHeader />
        <div className={classes.section}>
          <div className={classes.cardContainer}>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "16px" }}
            >
              <List
                title={"Usage"}
                items={[
                  "Pre- or post-workout; meal replacement for low-calorie diets.",
                ]}
              />
              <List
                title={"Advantages"}
                items={[
                  "Fast absorption, no bloating, and easy on digestion.",
                  "Vegan-friendly, promotes lean muscle.",
                ]}
              />
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "16px" }}
            >
              <List
                title={"Flavors (Powder)"}
                items={["Green Apple", " Raspberry Lemonade", " Grape"]}
                customGap="8px"
              />
              <List
                title={"Common Additives"}
                items={[
                  "Contains natural flavors and sweeteners like sucralose or stevia.",
                ]}
              />
            </div>
          </div>
        </div>
        <div className={classes.section} style={{ marginTop: "24px" }}>
          <div className={classes.sectionTitle}>
            Ultima Replenisher - Electrolyte Hydration Powder
          </div>
          <Table data={ultimaReplenisherTableData} />
        </div>
        <div className={classes.section} style={{ marginTop: "16px" }}>
          <div className={classes.cardContainer}>
            <List
              title={"Usage"}
              items={[
                "Ideal for daily use to maintain electrolyte balance.",
                "Perfect for replenishing electrolytes lost from sweating during exercise.",
              ]}
            />
            <List
              title={"Focus"}
              items={[
                "Provides balanced hydration without heavy sodium content.",
                "Suitable for light to moderate activity or daily hydration needs.",
              ]}
            />
          </div>
        </div>
        <div className={classes.section} style={{ marginTop: "24px" }}>
          <div className={classes.sectionTitle}>
            LMNT - Electrolyte Drink Mix
          </div>
          <Table data={electrolyteLMNTTableData} />
        </div>
        <div className={classes.section} style={{ marginTop: "16px" }}>
          <div className={classes.cardContainer}>
            <List
              title={"Usage"}
              items={[
                "Ideal for intense workouts, low-carb or keto diets, and preventing cramping.",
                "Replenishes lost electrolytes and supports hydration after heavy sweating.",
              ]}
            />
            <List
              title={"Focus"}
              items={[
                "Supports hydration, prevents cramps, and enhances performance during intense activity or heavy sweating. Ideal for athletes and keto dieters, replenishing electrolytes without sugar for optimal hydration.",
              ]}
            />
          </div>
        </div>
      </Layout>
      <Layout>
        <SmartFitHeader />
        <div className={classes.section}>
          <div className={classes.sectionTitle}>Which One to Choose?</div>
          <Table data={whichOneToChooseTableData} smallFirstColumn={true} />
        </div>
        <div className={classes.section} style={{ marginTop: "24px" }}>
          <div className={classes.sectionTitle}>
            Promix Creatine - Creatine Monohydrate Supplement
          </div>
          <Table data={promixCreatineTableData} />
        </div>
        <div className={classes.section} style={{ marginTop: "16px" }}>
          <div className={classes.cardContainer}>
            <List
              title={"Usage"}
              items={[
                "Supports muscle strength, endurance, and faster recovery by efficiently boosting cellular ATP production.",
                "Micronized for better absorption and easy mixing in beverages.",
              ]}
            />
            <List
              title={"Benefits"}
              items={[
                "Enhances high-intensity performance, supports lean muscle growth, and speeds up recovery between sets and workouts.",
                "Additive-free, vegan-friendly, and micronized for easy absorption and mixing",
              ]}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default SupplementsSmartFit;
